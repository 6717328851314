.container {
  @media screen and (min-width: 62em) {
    flex: 0 0 293px;
    max-width: 293px;
    margin-bottom: -124px;
  }
  @media screen and (max-width: 61.99em) {
    display: none;
  }
}
.sticky {
  position: sticky;
  top: 104px;
  height: 100vh;
  overflow-y: auto;
  padding-bottom: 124px;
  composes: no-scroll from 'assets/style/global.scss';
}
.cart {
  background-color: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
.arrowIcon {
  display: flex;
  align-items: center;
  composes: icon from 'assets/style/global.scss';
  &:before {
    content: '\e94b';
    font-size: 2rem;
    position: absolute;
    left: 16px;
  }
  @media screen and (min-width: 62em) {
    display: none;
  }
}
