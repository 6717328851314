.background {
  height: 72px;
  background: #eeeeee;
  position: absolute;
  top: 0;
  width: 100%;
}
.backgroundImg {
  width: 100%;
  background-image: url('/assets/images/banner_default.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 72px;

  @media screen and (max-width: 61.99em) {
    width: 100%;

    background-position: 50% 23%;
  }
}
.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px 16px;
}
.imgContainer {
  border: 8px solid #ffffff;
  width: 96px;
  height: 96px;
  margin-bottom: 24px;
  z-index: 1;
  border-radius: 50%;
  overflow: hidden;
}
.img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}
.shopName {
  font-size: 1.66rem;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 16px;
  max-width: 100%;
  composes: truncate from 'assets/style/global.scss';
}
.url {
  font-size: 1rem;
  line-height: 16px;
  color: var(--color-grey-1);
  max-width: 100%;
  composes: truncate from 'assets/style/global.scss';
}
.btnContainer {
  display: flex;
  margin-top: 24px;
  width: 100%;
}
.btn {
  flex: 1;
  max-width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1.16rem;
  line-height: 16px;
  font-weight: 700;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:not(:last-child) {
    margin-left: 8px;
  }
  @media screen and (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: #f5f5f5;
    }
  }
}
