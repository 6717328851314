:root {
  --color-light-blue: #0cafc6;
  --color-primary: transparent;
  --color-primary-hover: transparent;
  --color-primary-light: transparent;
  --color-golden: #ffc200;
  --color-golden-hover: #dca900;
  --color-grey-1: #616161;
  --color-grey-light: #f4f5f7;
  --color-error: #db2509;
  --color-success: #00b785;
  --color-light-white: #f5f5f5;
  --color-black: #141414;
  --real100vh: 100vh;
  --bg-color: #fff;
  --bg-white: #fff;
  --layer-bg-color: #f0f0f0;
  --layer-bg-color-hover: #e5e5e5;
  --body-bg: #F4F5F7;
  --z-color-light-hover: #f5f5f5;
}
