.main {
  position: sticky;
  left: 0;
  top: 0;
  right: 0;
  z-index: 102;
}
.background {
  background-color: #ffffff;
  position: relative;
  z-index: 1000;
  @media screen and (min-width: 62em) {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  }
  @media screen and (max-width: 61.99em) {
    box-shadow: 0px 1px 0px #eeeeee;
  }
}
.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
  position: relative;
  @media screen and (min-width: 62em) {
    padding: 16px 0;
  }
  @media screen and (max-width: 61.99em) {
    padding: 16px;
    justify-content: space-between;
    height: 56px;
  }
}
.rightSide {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (min-width: 62em) {
    flex-grow: 1;
  }
}
.logo {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-left: 0;
  flex: 0 0 162px;
  justify-content: center;
  max-width: 162px;
  max-height: 100%;
  @media screen and (max-width: 61.99em) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.action {
  min-height: 1px;
  padding-right: 0;
  padding-left: 0;
  position: relative;
  display: flex;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
}
.loginBtn {
  line-height: 16px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-shrink: 0;
  cursor: pointer;
  background: #ffffff;
  border-radius: 8px;
  padding: 8px;

  @media screen and (min-width: 62em) {
    margin-right: 8px;
  }
  @media screen and (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: #f5f5f5;
    }
  }
}
.name {
  composes: truncate from 'assets/style/global.scss';
  max-width: 110px;
  @media screen and (max-width: 61.99em) {
    display: none;
  }
}
.burgerMenu {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (min-width: 62em) {
    display: none;
  }
}
.burgerMenuIcon {
  composes: icon from 'assets/style/global.scss';
  &:before {
    content: '\e9af';
    font-size: 2rem;
    cursor: pointer;
    display: flex;
  }
}
