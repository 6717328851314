@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './colors.css';

//iransan
@font-face {
  font-family: IranSans;
  src: url('/assets/fonts/IRANSansWeb(FaNum)_Medium.eot');
  src: url('/assets/fonts/IRANSansWeb(FaNum)_Medium.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/IRANSansWeb(FaNum)_Medium.woff') format('woff'),
    url('/assets/fonts/IRANSansWeb(FaNum)_Medium.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: IranSans;
  src: url('/assets/fonts/IRANSansWeb(FaNum)_Bold.eot');
  src: url('/assets/fonts/IRANSansWeb(FaNum)_Bold.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/IRANSansWeb(FaNum)_Bold.woff') format('woff'),
    url('/assets/fonts/IRANSansWeb(FaNum)_Bold.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: IranSans;
  src: url('/assets/fonts/IRANSansWeb(FaNum).eot');
  src: url('/assets/fonts/IRANSansWeb(FaNum).eot?#iefix') format('embedded-opentype'), url('/assets/fonts/IRANSansWeb(FaNum).woff') format('woff'),
    url('/assets/fonts/IRANSansWeb(FaNum).ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

//yekan bakh
@font-face {
  font-family: YekanBakh;
  src: url('/assets/fonts/YekanBakhFaNumMedium.eot');
  src: url('/assets/fonts/YekanBakhFaNumMedium.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/YekanBakhFaNumMedium.woff') format('woff'),
    url('/assets/fonts/YekanBakhFaNumMedium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: YekanBakh;
  src: url('/assets/fonts/YekanBakhFaNumBold.eot');
  src: url('/assets/fonts/YekanBakhFaNumBold.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/YekanBakhFaNumBold.woff') format('woff'),
    url('/assets/fonts/YekanBakhFaNumBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: YekanBakh;
  src: url('/assets/fonts/YekanBakhFaNumHeavy.eot');
  src: url('/assets/fonts/YekanBakhFaNumHeavy.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/YekanBakhFaNumHeavy.woff') format('woff'),
    url('/assets/fonts/YekanBakhFaNumHeavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: ZarinExpress;
  src: url('/assets/fonts/ze-home-v1.eot');
  src: url('/assets/fonts/ze-home-v1.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/ze-home-v1.woff') format('woff'),
    url('/assets/fonts/ze-home-v1.ttf') format('truetype'), url('/assets/fonts/ze-home-v1.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@import './swiperConfig.scss';
@import './input.scss';
@import './antdConfig.scss';
@import './animation.scss';
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(134, 134, 134, 0.5);
  border-radius: 5px;
  border: transparent;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body,
html {
  font-family: 'IranSans', sans-serif;
  font-size: 12px;
  line-height: 16px;
  direction: rtl;
  scroll-behavior: smooth;
  box-sizing: border-box;
}

body {
  color: var(--color-black);
  background-color: #f4f5f7;
  position: relative;
  &.open-modal {
    overflow-y: hidden;
  }
}

a {
  text-decoration: none !important;
  color: var(--color-black);
  &:hover {
    color: inherit;
  }
}

button {
  border: none;
  background: unset;
  cursor: pointer;
}

pre {
  font-family: inherit;
  white-space: pre-line;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 0;
}

button,
input {
  font-family: inherit;
  &:focus,
  &:active {
    outline: none;
  }
}

main {
  padding: 2.5rem;
}

textarea {
  font-family: inherit;
  &:focus {
    outline: none;
  }
}

#root {
  position: relative;
}

.container {
  margin-right: auto;
  margin-left: auto;
  @media screen and (min-width: 62em) {
    padding-right: 16px;
    padding-left: 16px;
    min-width: 992px;
    max-width: 1232px;
  }
  @media screen and (max-width: 61.99em) {
    padding-right: 8px;
    padding-left: 8px;
    min-width: 100%;
    max-width: 100%;
  }
}

.p-container {
  margin-right: auto;
  margin-left: auto;
  @media screen and (min-width: 62em) {
    padding-right: 16px;
    padding-left: 16px;
    max-width: 1300px;
  }
  @media screen and (max-width: 61.99em) {
    min-width: 100%;
    max-width: 100%;
  }
}

.panel-withnav {
  @media screen and (min-width: 62em) {
    padding-top: 32px;
  }
  @media screen and (max-width: 61.99em) {
    // padding-top: 56px;
  }
}

.panel-box {
  @media screen and (min-width: 62em) {
    flex: 1 1 auto;
    min-width: 0;
    padding-right: 16px;
  }
  @media screen and (max-width: 61.99em) {
    flex: 0 0 100%;
    max-width: 100%;
    height: 100%;
    min-height: calc(var(--real100vh) - 56px);
    background-color: var(--bg-white);
    padding-bottom: 16px;
  }
}

.blog-layout {
  @media screen and (min-width: 62em) {
    flex: 1 1 auto;
    min-width: 0;
    padding-right: 16px;
  }
  @media screen and (max-width: 61.99em) {
    flex: 0 0 100%;
    max-width: 100%;
    height: 100%;
    min-height: calc(var(--real100vh) - 56px);
    background-color: var(--body-bg);
    padding-bottom: 16px;
  }
}

.icon {
  &:before,
  &:after {
    font-family: ZarinExpress;
    font-size: 100%;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    text-decoration: none;
    text-transform: none;
    line-height: 1;
  }
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

figcaption,
figure,
main {
  display: block;
}

img {
  border-style: none;
}

img,
img[alt] {
  line-height: 22px;
}

.profile {
  display: flex;
  flex-wrap: wrap;
  margin-right: auto;
  margin-left: auto;
  @media screen and (min-width: 62em) {
    min-width: 808px;
    max-width: 808px;
    padding-top: 72px;
  }
  @media screen and (max-width: 61.99em) {
    padding: 80px 0 40px;
  }
}

.profile__sidebar--border {
  width: 4px;
  height: 24px;
  border-radius: 2px 0px 0px 2px;
  position: absolute;
  right: 0;
  display: none;
}

.panel {
  display: flex;
  justify-content: center;
  @media screen and (min-width: 62em) {
    padding-bottom: 48px;
  }
}

.panel-dashboard {
  &:before {
    content: '\ea49';
  }
}

.panel-grid {
  &:before {
    content: '\ea52';
  }
}

.panel-category {
  &:before {
    content: '\ea4d';
  }
}

.panel-order {
  &:before {
    content: '\ea4f';
  }
}

.panel-shipment {
  &:before {
    content: '\e919';
  }
}

.panel-discount {
  &:before {
    content: '\ea4b';
  }
}

.panel-message {
  &:before {
    content: '\ea4e';
  }
}

.panel-comment {
  &:before {
    content: '\ea4a';
  }
}

.seo-icon {
  &:before {
    content: '\e98e';
  }
}

.domain-connect-icon {
  &:before {
    content: '\e9a2';
  }
}

.main-header {
  animation-duration: 0.5s;
  background: linear-gradient(180deg, var(--color-primary) -119.17%, #273a7f 100%);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 100;
}

.no-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.product-title {
  font-size: 1rem;
  line-height: 25px;
  color: #424242;
  text-align: right;
  margin-top: 8px;
  width: 100%;
  display: block;
  overflow: hidden;
  @extend .twoLineTruncate;
}

.old-price {
  text-align: right;
  letter-spacing: 0.5px;
  color: var(--color-grey-1);
  text-decoration: line-through;
  font-size: 1.16rem;
  line-height: 16px;
  margin-bottom: 8px;
}

.price-percent {
  color: #fff;
  letter-spacing: 0;
  display: flex;
  align-items: center;
  font-size: 1.33rem;
  line-height: 12px;
  min-width: 32px;
  text-align: center;
  background-color: var(--color-success);
  padding: 7px 8px 5px;
  position: absolute;
  top: 8px;
  right: 8px;
  border-radius: 12px;
  span {
    &:first-child {
      display: flex;
      align-items: center;
      &::after {
        @extend .icon;
        content: '\e910';
        font-size: 1rem;
      }
    }
  }
}

.new-price {
  color: #000;
  letter-spacing: 0;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 16px;
  text-align: right;
  display: flex;
  align-items: center;
}

.price-currency {
  margin-right: 4px;
  font-size: 1.14rem;
  line-height: 16px;
}

.product-quantity {
  display: flex;
  height: 40px;
  width: 120px;
  &.hidden {
    display: none;
  }
  &__item {
    font-size: 1.33rem;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    width: 40px;
    height: 40px;
    &:before {
      @extend .icon;
      font-size: 2rem;
    }
    &:disabled {
      cursor: default;
      background-color: #e0e0e0;
      &:before {
        color: #c5c4c6;
      }
    }
  }
  &__minus {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    &:before {
      content: '\e9b6';
      @extend .icon;
      font-size: 2rem;
      @media screen and (min-width: 62em) {
        font-size: 2rem;
      }
      @media screen and (max-width: 61.99em) {
        font-size: 1.7rem;
      }
    }
    @media screen and (hover: hover) and (pointer: fine) {
      &:not(:disabled):hover {
        background-color: #f5f5f5;
      }
    }
  }
  &__add {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    @media screen and (hover: hover) and (pointer: fine) {
      &:not(:disabled):hover {
        background-color: #f5f5f5;
      }
    }
    &:before {
      content: '\e9ce';
      @media screen and (min-width: 62em) {
        font-size: 2rem;
      }
      @media screen and (max-width: 61.99em) {
        font-size: 1.7rem;
      }
    }
  }
  &__delete {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    cursor: pointer;
    @media screen and (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #f5f5f5;
      }
    }
    &:before {
      content: '\ea04';
      @media screen and (min-width: 62em) {
        font-size: 2rem;
      }
      @media screen and (max-width: 61.99em) {
        font-size: 1.7rem;
      }
    }
  }
}

//button
.mini-add-to-cart--btn {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.33rem;
  line-height: 16px;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 8px;
  width: 40px;
  height: 40px;
  transition: all 1s linear;
  &:before {
    @extend .icon;
    font-size: 2rem;
    content: '\e9ce';
    @media screen and (min-width: 62em) {
      font-size: 2rem;
    }
    @media screen and (max-width: 61.99em) {
      font-size: 1.7rem;
    }
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: #f5f5f5;
    }
  }
}

.btn {
  display: flex;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  padding: 0;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
}

.btn--like {
  @extend .btn;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 4px 8px;
  color: #9e9e9e;
  font-size: 1rem;
  line-height: 16px;
  &::after {
    content: '\e9ff';
    @extend .icon;
    font-size: 1rem;
    margin-right: 9px;
  }
}

.btn--blue {
  @extend .btn;
  border-radius: 16px;
  font-size: 1rem;
  line-height: 16px;
  color: #fff;
  background-color: var(--color-light-blue);
  padding: 8px 16px;
  text-align: center;
}

.btn--blue-1 {
  @extend .btn--blue;
  font-weight: 700;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  &.disable {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.btn-shop--primary {
  font-size: 1.33rem;
  line-height: 24px;
  color: #fff;
  padding: 12px 24px;
  background-color: var(--color-black);
  font-weight: 700;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  .spinner {
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-top: 2px solid #ffffff;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }
  &.disable {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &:disabled {
    background: #e0e0e0;
    cursor: not-allowed;
  }
}

.btn--primary {
  border-radius: 8px;
  color: #fff;
  justify-content: center;
  @extend .btn;
  background-color: var(--color-primary) !important;
  padding: 13px 24px;
  font-size: 1.33rem;
  font-weight: 700;
  height: 48px;
  @media screen and (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: var(--color-primary-hover);
    }
  }
  &:hover {
    color: #ffffff;
  }
  &.loading {
    background: var(--color-primary);
    opacity: 0.8;
    .spinner {
      border: 2px solid rgba(255, 255, 255, 0.1);
      border-top: 2px solid #ffffff;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      animation: spin 1s linear infinite;
    }
  }
  &.disabled {
    opacity: 0.5;
  }
}

.spinner {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-top: 2px solid #ffffff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn--new-product {
  @extend .btn--primary;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  line-height: 24px;
  &::before {
    content: '\e9ce';
    @extend .icon;
    @media screen and (min-width: 62em) {
      font-size: 2rem;
      margin-left: 12px;
    }
    @media screen and (max-width: 61.99em) {
      font-size: 1.16rem;
      margin-left: 8px;
    }
  }
  @media screen and (min-width: 62em) {
    padding: 8px 12px 8px 20px;
  }
  @media screen and (max-width: 61.99em) {
    font-size: 0.9rem;
    padding: 8px;
  }
}

.btn--zarinpal {
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  font-size: 1.16rem;
  line-height: 24px;
  color: var(--color-grey-1);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 12px 24px;
  width: 100%;
  height: 48px;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
    color: var(--color-grey-1);
  }
  &::before {
    content: '';
    background: url('/assets/images/svg/zarinpal-logo-small.svg') no-repeat;
    float: right;
    width: 24px;
    height: 24px;
    margin-left: 1rem;
  }
}

.btn--p-create {
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  line-height: 24px;
  font-size: 1.16rem;
  padding: 8px 12px 8px 16px;
  border-radius: 8px;
  color: #fff;
  background-color: var(--color-primary);
  &::before {
    content: '\e9ce';
    @extend .icon;
    @media screen and (min-width: 62em) {
      font-size: 2rem;
      margin-left: 8px;
    }
    @media screen and (max-width: 61.99em) {
      font-size: 1.16rem;
      margin-left: 8px;
    }
  }
  &:hover {
    background-color: var(--color-primary-hover);
    color: #ffffff !important;
  }
}

.btn--bordered {
  @extend .btn;
  padding: 8px 24px;
  border: 1px solid #e0e0e0;
  border-radius: 24px;
  font-weight: 900;
}

.btn--yellow {
  @extend .btn;
  background: linear-gradient(270deg, #ffd400 -11.07%, #ffe422 100%);
  border-radius: 24px;
  padding: 16px;
  height: 48px;
  font-weight: 900;
  font-size: 1.33rem;
  line-height: 16px;
  @media screen and (hover: hover) and (pointer: fine) {
    &:hover {
      box-shadow: 0px 0px 16px rgba(255, 212, 0, 0.4);
    }
  }
}

.btn--border-grey {
  @extend .btn;
  border-radius: 8px;
  padding: 8px;
  border: 1px solid #eeeeee;
  color: #424242;
  justify-content: center;
}

.btn--grey {
  @extend .btn;
  background: #f5f5f5 !important;
  border-radius: 8px;
  font-size: 1.16rem;
  line-height: 16px;
  color: #424242;
  padding: 16px;
  justify-content: center;
}

.btn-upload {
  background-color: #fafafa;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  padding: 11px;
  color: #424242;
  font-size: 1rem;
  line-height: 24px;
  display: flex;
  align-items: center;
  &:before {
    @extend .icon;
    margin-left: 8px;
    font-size: 2rem;
  }
}

.btn--attachment {
  @extend .btn-upload;
  &:before {
    content: '\ea50';
  }
}

.btn--download {
  @extend .btn-upload;
  &:before {
    content: '\e972';
  }
}

.btn--English {
  text-align: left;
  direction: ltr;
}

.btn--red-close {
  @extend .btn;
  color: #fff;
  background-color: red;
  padding: 6px 30px;
  font-weight: 700;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.2rem;
  line-height: 25px;
}

.btn--panel-cancel {
  @extend .btn;
  background-color: #fafafa;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  padding: 15px 16px;
  font-size: 1.16rem;
  line-height: 16px;
  color: var(--color-black);
  justify-content: center;
}

.btn--panel-success {
  @extend .btn;
  background-color: var(--color-success);
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 1.16rem;
  line-height: 16px;
  color: #fff;
}

.btn--dashboard {
  background-color: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 16px;
  color: #424242;
  font-weight: 700;
  @media screen and (min-width: 62em) {
    font-size: 1.16rem;
  }
  @media screen and (max-width: 61.99em) {
    font-size: 1rem;
  }
  &:before {
    @extend .icon;
    margin-left: 8px;
    @media screen and (min-width: 62em) {
      font-size: 2rem;
    }
    @media screen and (max-width: 61.99em) {
      font-size: 1.33rem;
    }
  }
  span {
    padding: 6px 8px;
    border-radius: 50%;
    color: #fff;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 62em) {
      width: 24px;
      height: 24px;
    }
    @media screen and (max-width: 61.99em) {
      width: 20px;
      height: 20px;
    }
  }
}

.btn--bordered-rate {
  box-sizing: border-box;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  font-size: 1rem;
  line-height: 16px;
}

.btn--current-week {
  @extend .btn--bordered-rate;
  border: 1px solid #e0e0e0;
  color: var(--color-grey-1);
}

.btn--current-month {
  @extend .btn--bordered-rate;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  background: rgba(39, 58, 127, 0.08);
}

.btn--upload-attachment {
  display: flex;
  align-items: center;
  font-size: 1.16rem;
  line-height: 24px;
  color: #424242;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 12px;
  &:before {
    content: '\e9c4';
    @extend .icon;
    font-size: 2rem;
    color: var(--color-primary);
    margin-left: 8px;
  }
}

.p-top {
  @media screen and (min-width: 62em) {
    margin-top: 80px;
  }
  @media screen and (max-width: 61.99em) {
    padding-top: 122px;
  }
}

//tab
.tab {
  width: 100%;
  display: flex;
  list-style: none;
  max-width: 100%;
  display: flex;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: auto;
  @extend .no-scroll;
  &__pill {
    cursor: pointer;
    position: relative;
    display: flex;
    &--link {
      display: flex;
      align-items: center;
    }
  }
  &__container {
    width: 100%;
    display: flex;
    box-sizing: border-box;
  }
}

.tab-content {
  display: flex;
  flex-wrap: wrap;
  &__container {
    display: none;
    width: 100%;
    &--active {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.blue-tab {
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  .tab__pill {
    border-radius: 16px;
    text-align: center;
    padding: 11px 0;
    color: var(--color-grey-1);
    line-height: 24px;
    font-weight: 700;
    display: flex;
    align-items: center;
    @media screen and (min-width: 62em) {
      font-size: 1.16rem;
      &:not(:last-of-type) {
        margin-left: 24px;
      }
    }
    @media screen and (max-width: 61.99em) {
      font-size: 1rem;
      &:not(:last-of-type) {
        margin-left: 12px;
      }
    }
    &--active {
      color: var(--color-primary) !important;
      position: relative;
      &::after {
        content: '';
        height: 2px;
        width: 100%;
        border: var(--color-primary);
        border-radius: 2px 2px 0px 0px;
        position: absolute;
        bottom: 0;
        right: 0;
        background-color: var(--color-primary);
      }
      .blue-tab__badge {
        background-color: var(--color-primary);
        border-color: var(--color-primary);
        color: #ffffff;
      }
    }
  }
  &__badge {
    border-radius: 16px;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    color: var(--color-grey-1);
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-grey-1);
  }
}

.with-nav {
  @media screen and (min-width: 62em) {
    padding-top: 113px;
  }
  @media screen and (max-width: 61.99em) {
    padding-top: 105px;
  }
}

.without-nav {
  @media screen and (min-width: 62em) {
    padding-top: 104px;
  }
  @media screen and (max-width: 61.99em) {
    padding-top: 80px;
  }
}

.p-without-nav {
  @media screen and (min-width: 62em) {
    padding-top: 88px;
  }
  @media screen and (max-width: 61.99em) {
    padding-top: 50px;
  }
}

.product {
  &__card {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    box-sizing: border-box;
    height: 100%;
    position: relative;
    max-height: 355px;
    min-height: 355px;
    @media screen and (min-width: 62em) {
      min-height: 316px;
    }
    @media screen and (max-width: 61.99em) {
      min-height: 355px;
    }
  }
  &__img {
    border-radius: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    overflow: hidden;
    @media screen and (min-width: 62em) {
      a {
        width: 196px;
        height: 196px;
      }
    }
    @media screen and (max-width: 61.99em) {
      a {
        width: 183px;
        height: 183px;
      }
    }
    img {
      max-width: 100%;
      max-height: 100%;
      min-height: 1px;
      width: auto \9;
      height: auto;
      width: 100%;
    }
  }
  &__title {
    font-size: 1rem;
    line-height: 25px;
    color: #424242;
    text-align: right;
    margin-top: 8px;
    width: 100%;
    display: block;
    @extend .twoLineTruncate;
  }
  &__price-box {
    width: 100%;
    display: flex;
    align-items: flex-end;
    position: absolute;
    @media screen and (min-width: 62em) {
      justify-content: space-between;
      bottom: 0;
      left: 0;
    }
    @media screen and (max-width: 61.99em) {
      flex-wrap: wrap;
      justify-content: center;
      bottom: 12px;
      left: 12px;
      width: calc(100% - 24px);
      flex-direction: column-reverse;
    }
  }
  &__price {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    @media screen and (max-width: 61.99em) {
      margin-bottom: 12px;
    }
    del {
      display: block;
      letter-spacing: 0.5px;
      color: var(--color-grey-1);
      text-decoration: line-through;
      padding-top: 3px;
      font-size: 1.143rem;
      line-height: 1.375;
    }
  }
  &__percent {
    color: #fff;
    border-radius: 0px 10px 10px 10px;
    letter-spacing: 0;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    font-size: 1rem;
    line-height: 1.375;
    margin-right: 4px;
    min-width: 32px;
    height: 23px;
    text-align: center;
    background-color: var(--color-light-blue);
    padding: 5px 8px;
  }
  &__new-price {
    width: 100%;
    color: #000;
    letter-spacing: 0;
    font-weight: 700;
    font-size: 1.429rem;
    line-height: 1.1;
    text-align: left;
    display: flex;
    justify-content: flex-end;
  }
  &__currency {
    display: flex;
    &:before {
      content: '\ea02';
      @extend .icon;
      font-size: 16px;
      margin-right: 4px;
    }
  }
  &__timer-box {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 1.5rem;
    @media screen and (max-width: 65.437em) {
      margin-top: 12px;
      margin-left: 35px;
    }
  }
  &__timer {
    font-size: 1rem;
    line-height: 1.571;
    color: #737373;
    margin-top: 5px;
    display: flex;
    align-items: center;
    &::after {
      @extend .icon;
      content: '\E0F1\00FE0E';
      font-size: 15px;
      color: #737373;
      margin-right: 4px;
    }
  }
  &__quantity {
    border: 1px solid #112363;
    border-radius: 12px;
    padding: 8px;
    display: flex;
    color: #112363;
    @media screen and (min-width: 62em) {
      height: 40px;
      width: 105px;
    }
    @media screen and (max-width: 61.99em) {
      height: 32px;
      width: 80px;
    }
    &.hidden {
      display: none;
    }
    &--item {
      flex: 0 0 33.3%;
      font-size: 1.33rem;
      line-height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:before {
        @extend .icon;
        font-size: 2rem;
      }
    }
    &--delete {
      &:before {
        content: '\ea04';
        @extend .icon;
        cursor: pointer;
        @media screen and (min-width: 62em) {
          font-size: 2rem;
        }
        @media screen and (max-width: 61.99em) {
          font-size: 1.7rem;
        }
      }
    }
    &--minus {
      cursor: pointer;
      &:before {
        content: '\e9b6';
        @extend .icon;
        font-size: 2rem;
        @media screen and (min-width: 62em) {
          font-size: 2rem;
        }
        @media screen and (max-width: 61.99em) {
          font-size: 1.7rem;
        }
      }
    }
    &--add {
      cursor: pointer;
      &:before {
        content: '\e9ce';
        @media screen and (min-width: 62em) {
          font-size: 2rem;
        }
        @media screen and (max-width: 61.99em) {
          font-size: 1.7rem;
        }
      }
    }
  }
}

.collapse-plus-icon {
  @extend .icon;
  &:after {
    content: '\E9CE';
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 2rem;
    transition: all 0.3s ease-in-out;
  }
}

.collapse-minus-icon {
  @extend .icon;
  display: flex;
  &:after {
    content: '\E9B6';
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 2rem;
    transition: all 0.3s ease-in-out;
  }
}

.collapse-arrow-icon {
  @extend .icon;
  display: flex;
  transform: rotate(-90deg) !important;
  transition: all 0.3s ease-in-out;
  &:after {
    content: '\E905';
    font-size: 2rem;
  }
  &.rotate {
    transform: rotate(90deg) !important;
  }
}

//icon
.store-icon {
  display: flex;
  align-items: center;
  &::before {
    @extend .icon;
    content: '\EA3A';
    margin-left: 8px;
    font-size: 1.33rem;
  }
}

.headphone-icon {
  display: flex;
  align-items: center;
  &::before {
    @extend .icon;
    content: '\e993';
    margin-left: 8px;
    font-size: 1.33rem;
  }
}

.arrow-back-icon {
  margin-left: 24px;
  cursor: pointer;
  &:after {
    content: '\e92d';
    @extend .icon;
    font-size: 2rem;
    display: flex;
  }
}

.close-icon {
  margin-left: 16px;
  cursor: pointer;
  &:after {
    content: '\ea24';
    @extend .icon;
    font-size: 2rem;
    display: flex;
  }
}

.overlay {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  background: rgb(20, 20, 20, 0.18);
  opacity: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transition: all 0.5s ease-in-out;
}

.modal-overlay {
  @extend .overlay;
  z-index: 60;
  &.show {
    display: block;
    opacity: 1;
    pointer-events: auto;
    animation: fadein 0.8s;
  }
  &.hide {
    animation: fadeout 0.8s;
  }
}

.modal-close-btn {
  color: #000;
  font-size: 29px;
  float: left;
  padding: 16px;
  &::before {
    content: '\ea24';
    @extend .icon;
    font-size: 2rem;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover,
    :focus {
      color: rgb(0, 0, 0);
      text-decoration: none;
      cursor: pointer;
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.p-sidebar-profile {
  @extend .icon;
  &:before {
    content: '\ea48';
  }
}

.p-sidebar-order {
  @extend .icon;
  &:before {
    content: '\ea43';
  }
}

.p-sidebar-address {
  @extend .icon;
  &:before {
    content: '\ea44';
  }
}

.p-sidebar-following {
  @extend .icon;
  &:before {
    content: '\e903';
  }
}

.p-sidebar-favorite {
  @extend .icon;
  &:before {
    content: '\ea40';
  }
}

.p-sidebar-message {
  @extend .icon;
  &:before {
    content: '\ea47';
  }
}

.p-sidebar-comment {
  @extend .icon;
  &:before {
    content: '\ea42';
  }
}

.bg-blue {
  background-color: var(--color-light-blue);
}

.bg-success {
  background-color: var(--color-success);
}

.bg-error {
  background-color: var(--color-error);
}

.modal {
  &__header {
    font-size: 1.5rem;
    line-height: 32px;
    display: flex;
    font-weight: 700;
    position: relative;
    @media screen and (min-width: 62em) {
      justify-content: space-between;
      padding: 24px;
    }
    @media screen and (max-width: 61.99em) {
      padding: 16px;
      flex-direction: row-reverse;
      justify-content: center;
      margin-bottom: 16px;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
    }
  }
  &__close {
    &::before {
      content: '\ea24';
      @extend .icon;
      font-size: 2rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      @media screen and (max-width: 61.99em) {
        position: absolute;
        right: 16px;
        top: 20px;
      }
    }
  }
}

.address-scroll {
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #b4b4b4;
    border-radius: 5px;
    margin: 5px 0;
  }
}

.p-gender-container {
  width: 100%;
  margin-top: 16px;
}

.p-gender-title {
  font-size: 1.16rem;
  line-height: 24px;
  color: var(--color-black);
}

.Toastify__toast {
  background: var(--color-black);
  opacity: 0.85;
  color: #ffffff;
  font-size: 14px;
  line-height: 24px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  font-family: 'IranSans', sans-serif;
  min-height: 52px;
}

.Toastify__close-button {
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-38%);
  left: 14px;
}

.Toastify__progress-bar--default {
  background: var(--color-light-blue);
}

.Toastify__toast--error {
  background: #db2509;
  opacity: 1;
  margin: 12px;
  .Toastify__close-button {
    display: none;
  }
  .Toastify__toast-body {
    display: flex;
    align-items: center;
    padding: 0;
  }
}

.Toastify__toast--success {
  background: var(--color-success);
  opacity: 1;
  margin: 12px;
  .Toastify__close-button {
    display: none;
  }
  .Toastify__toast-body {
    display: flex;
    align-items: center;
  }
}

.Toastify__toast--warning {
  .Toastify__toast-body {
    display: flex;
    align-items: center;
    &:before {
      content: '';
      width: 24px;
      height: 24px;
      margin-left: 8px;
      background: url('/assets/images/svg/warning.svg') no-repeat;
    }
  }
}

.burger-menu {
  touch-action: none;
  .ant-drawer-body {
    padding: 0;
  }
}

.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.twoLineTruncate {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.market-top {
  margin-top: 55px;
}

#landingNav {
  li {
    a {
      &.activeLink {
        color: var(--color-primary);
      }
      &.active {
        color: var(--color-primary);
      }
    }
  }
}

.landing-marquee {
  direction: ltr;
  white-space: nowrap;
  width: 100%;
  div {
    div {
      display: flex !important;
    }
  }
}

.invoice-detail__icon {
  &--file {
    &::after {
      content: '\e982';
    }
  }
  &--option {
    &::after {
      content: '\e99e';
    }
  }
  &--package {
    &::after {
      content: '\e9c3';
    }
  }
  &--truck {
    &::after {
      content: '\e919';
    }
  }
  &--img {
    &::after {
      content: '\e998';
    }
  }
  &--alert {
    &::after {
      content: '\e91f';
    }
  }
  &--cart {
    &::after {
      content: '\e9e7';
    }
  }
  &--check {
    &::after {
      content: '\e948';
    }
  }
}

.DatePicker {
  width: 100%;
}

.login-container {
  position: relative;
  @media screen and (min-width: 62em) {
    min-height: 100vh;
  }
  @media screen and (max-width: 61.99em) {
    background-color: #fff;
    // height: calc(var(--vh, 1vh) * 100);
  }
}

.create-shop__crop {
  &--logo {
    .reactEasyCrop_Image {
      height: 120px;
    }
  }
  &--banner {
    .reactEasyCrop_Image {
      width: 489px;
    }
  }
  &--area {
    color: rgba(0, 0, 0, 0.25) !important;
  }
}

.shop-steps-buy {
  display: flex;
  justify-content: center;
  @media screen and (min-width: 62em) {
    padding-top: 104px;
  }
  @media screen and (max-width: 61.99em) {
    padding-top: 72px;
  }
}

.btnContainer {
  display: flex;
  margin-top: 32px;
  justify-content: flex-end;
  padding: 16px;
  border-top: 1px solid #eeeeee;
  background-color: #fff;
  @media screen and (max-width: 61.99em) {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 100;
  }
}

.star {
  &:before {
    content: attr(data-content);
    color: var(--color-error);
    margin-left: 4px;
  }
}

.createLink {
  color: var(--color-primary) !important;
  font-weight: 700;
  position: absolute;
  left: 18px;
  cursor: pointer;
}

.draftBtn {
  cursor: pointer;
  font-size: 1.16rem;
  line-height: 16px;
  color: var(--color-primary);
  font-weight: 700;
  margin-left: 8px;
  &:hover {
    color: var(--color-primary);
  }
  @media screen and (min-width: 62em) {
    padding: 16px 24px;
  }
  @media screen and (max-width: 61.99em) {
    padding: 16px;
  }
}

/* map */

#map {
  height: 200px;
}

.mapboxgl-canvas {
  border-radius: 8px !important;
}

.dir-ltr * {
  direction: ltr !important;
}

.dir-rtl * {
  direction: rtl !important;
}

.ltr {
  direction: ltr !important;
}

.rtl {
  direction: rtl !important;
}

/* draft.js */

.rdw-editor-wrapper {
  direction: ltr !important;
}

.fr-element.fr-view h1 {
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.fr-element.fr-view h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.fr-element.fr-view h3 {
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.fr-element.fr-view h4 {
  display: block;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.fr-element.fr-view h5 {
  display: block;
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.fr-element.fr-view h6 {
  display: block;
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.rdw-editor-main {
  min-height: 350px;
  padding: 10px;
  border: 1px solid var(--color-grey-light);
}

/* create post */

.post-category .ant-checkbox + span {
  flex: 1;
}

/* editor */
.fr-box.fr-basic .fr-element {
  min-height: 410px !important;
}

[data-cmd="imageReplace"] {
  display: none !important;
}