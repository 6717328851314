.box {
  position: relative;
  height:100vh;
  width: 100%;
}
.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 16px;
}
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  &:before {
    width: 264px;
    height: 140px;
    content: '';
    background: url('/assets/images/svg/404-Green.svg') no-repeat;
  }
}

.title {
  margin-top: 32px;
  margin-bottom: 32px;
  font-size: 1.66rem;
  line-height: 32px;
  font-weight: 700;
}
.text {
  font-size: 1.16rem;
  line-height: 24px;
  color: var(--color-grey-1);
  max-width: 218px;
}
