.container {
  width: 220px;
  position: absolute;
  margin-right: -154px;
  z-index: 1000;
  padding-top: 10px;
  @media screen and (max-width: 61.99em) {
    left: 0;
  }
}
.cart {
  background: #ffffff;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  overflow: hidden;
}
.profile {
  @media screen and (min-width: 62em) {
    top: 61px;
  }
  @media screen and (max-width: 61.99em) {
    top: 46px;
  }
}
.panel {
  @media screen and (min-width: 62em) {
    top: 43px;
  }
  @media screen and (max-width: 61.99em) {
    top: 27px;
  }
}
.shop {
  @media screen and (min-width: 62em) {
    top: 52px;
  }
  @media screen and (max-width: 61.99em) {
    top: 50px;
  }
}
.header {
  padding: 16px;
  display: flex;
  composes: icon from 'assets/style/global.scss';
  position: relative;
}
.hover {
  cursor: pointer;
  @media screen and (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: #f5f5f5;
      &::after {
        content: '\e94b';
        font-size: 1.35rem;
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
.imgContainer {
  width: 40px;
  height: 40px;
  margin-left: 8px;
}
.img {
  width: 100%;
}
.title {
  font-size: 1.16rem;
  line-height: 16px;
  color: var(--color-black);
  font-weight: 700;
  margin-bottom: 8px;
}
.text {
  font-size: 0.833rem;
  line-height: 16px;
  color: #424242;
}

.logoutLink {
  font-size: 1.16rem;
  display: flex;
  align-items: center;
  line-height: 16px;
  padding: 16px;
  position: relative;
  width: 100%;
  composes: icon from 'assets/style/global.scss';
  &:before {
    content: '\ea46';
    font-size: 2rem;
    margin-left: 16px;
  }
}
