//tab
.default-tab {
  width: 100%;
  .ant-tabs-tab {
    border-radius: 16px;
    text-align: center;
    padding: 11px 0;
    color: var(--color-grey-1);
    line-height: 24px;
    font-weight: 700;
    display: flex;
    align-items: center;
    @media screen and (min-width: 62em) {
      font-size: 1.16rem;
      &:not(:last-of-type) {
        margin-right: 24px;
      }
    }
    @media screen and (max-width: 61.99em) {
      font-size: 1rem;
      &:not(:last-of-type) {
        margin-left: 12px;
      }
    }
  }
  .ant-tabs-tab-btn {
    display: flex;
    align-items: center;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--color-primary) !important;
    position: relative;
    font-weight: 700;
    .blue-tab__badge {
      background-color: var(--color-primary);
      border-color: var(--color-primary);
      color: #ffffff;
    }
  }
  .ant-tabs-tab-btn:focus,
  .ant-tabs-tab-remove:focus,
  .ant-tabs-tab-btn:active,
  .ant-tabs-tab-remove:active {
    color: var(--color-grey-1);
  }
  .ant-tabs-ink-bar {
    background: var(--color-primary);
  }
  .ant-tabs-tab-active {
    // &::after {
    //   content: "";
    //   height: 2px;
    //   width: 100%;
    //   border: var(--color-primary);
    //   border-radius: 2px 2px 0px 0px;
    //   position: absolute;
    //   bottom: 0;
    //   right: 0;
    //   background-color: var(--color-primary);
    // }
  }
}

.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  box-shadow: none !important;
  border: 2px solid var(--color-primary) !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover:not(.ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-status-error) {
  border-color: #e0e0e0 !important;
}

.shop-tab {
  .ant-tabs-ink-bar {
    background-color: var(--color-black) !important;
  }
  @media screen and (min-width: 62em) {
    display: none;
  }
  .ant-tabs-nav {
    margin: 0 !important;
  }
  .ant-tabs-nav-list {
    width: 100%;
  }
  .ant-tabs-tab {
    width: 50%;
    margin: 0 !important;
    justify-content: center;
    font-size: 1.16rem;
    line-height: 24px;
  }
  .ant-tabs-tabpane {
    background-color: #f4f5f7;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--color-black) !important;
    position: relative;
    font-weight: 700;
  }
  .ant-tabs-tab-btn {
    color: var(--color-grey-1);
  }
}

.profile-tab {
  .ant-tabs-ink-bar {
    background-color: var(--color-black) !important;
  }
  .ant-tabs-tab {
    &:first-of-type {
      margin-right: 16px;
    }
    @media screen and (min-width: 62em) {
      font-size: 1.16rem;
      margin-left: 48px !important;
      &:not(:first-of-type) {
        margin-right: 0;
      }
    }
    @media screen and (max-width: 61.99em) {
      font-size: 1rem;
      &:not(:last-of-type) {
        margin-left: 40px;
      }
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    a {
      color: var(--color-black) !important;
      position: relative;
      font-weight: 700;
    }
  }
  .ant-tabs-ink-bar {
    margin-right: 16px;
  }
  .ant-tabs-nav-wrap {
    &::before {
      display: none !important;
    }
    &::after {
      display: none !important;
    }
  }
  .ant-tabs-nav-operations {
    display: none !important;
  }
}

.product-edit-tab {
  .ant-tabs-tab {
    flex: 0 0 33% !important;
    margin: 0 !important;
    .ant-tabs-tab-btn {
      width: 100%;
      justify-content: center;
    }
  }
  .ant-tabs-nav-list {
    width: 100%;
  }
}

.dashboard-rate-tab {
  .ant-tabs-nav-wrap {
    &::before {
      display: none;
    }
  }
  .ant-tabs-nav-operations {
    display: none !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    background-color: #ffffff !important;
    .ant-tabs-tab-btn {
      color: var(--color-black) !important;
    }
  }
  .ant-tabs-ink-bar {
    top: 0 !important;
    height: 4px !important;
    background-color: var(--color-success) !important;
  }
  .ant-tabs-tab {
    flex: 1 !important;
    margin: 0 !important;
    padding: 16px 0;
    text-align: center;
    background-color: #fafafa;
    @media screen and (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #f5f5f5;
      }
    }
    &:not(:nth-child(3)) {
      border-left: 1px solid #eeeeee;
    }
    &:hover {
      color: var(--color-black);
    }
    .ant-tabs-tab-btn {
      width: 100%;
      justify-content: center;
    }
  }
  .ant-tabs-nav {
    margin-bottom: 24px !important;
  }
  .ant-tabs-nav-list {
    width: 100%;
  }
}

//slider
.crop-slider {
  width: 160px;
}

.landing-back-icon {
  left: 60px !important;
  right: auto !important;
}

.ant-input:focus,
.ant-input-focused {
  box-shadow: none;
}

.ant-input:hover:not(.ant-input:focus) {
  border-color: #e0e0e0;
}

/* select input */

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector,
.ant-input-affix-wrapper-status-error,
.ant-input-status-error {
  border: 2px solid var(--color-error) !important;
}

.ant-form-item-explain-error {
  color: var(--color-error) !important;
  font-size: 1rem !important;
  margin-right: 16px !important;
}

.ant-col.ant-form-item-label.ant-col-rtl {
  margin-bottom: 7px !important;
  padding-bottom: 0 !important;
}

.ant-radio-checked::after,
.ant-radio-inner::after {
  box-shadow: inset 0 0 0 5px #fff !important;
  background-color: var(--color-light-blue) !important;
  border-color: var(--color-light-blue) !important;
}

.ant-radio-inner {
  width: 25px !important;
  height: 25px !important;
}

.ant-radio-inner::after {
  top: 9% !important;
  left: 9% !important;
  width: 35px !important;
  height: 35px !important;
}

.ant-radio-wrapper {
  align-items: center !important;
}

.ant-input[disabled] {
  color: #9e9e9e !important;
}

span.ant-radio + * {
  width: 100% !important;
  padding-left: 0 !important;
}

/* checkbox */

.ant-checkbox-input {
  visibility: hidden !important;
  position: absolute !important;
}

.ant-checkbox-inner {
  width: 24px !important;
  height: 24px !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  width: 24px !important;
  height: 24px !important;
  background: var(--color-light-blue) !important;
  border: 1px solid var(--color-light-blue) !important;
}

.ant-checkbox-checked .ant-checkbox-inner:after {
  content: '\e947' !important;
  @extend .icon;
  position: absolute !important;
  left: 56% !important;
  top: 56% !important;
  padding: 6px !important;
  color: #fff !important;
  font-size: 1.33em !important;
  transform: translate(-50%, -50%) !important;
}

.ant-checkbox-wrapper.ant-checkbox-rtl {
  align-items: center !important;
}

/* select solid type*/

#selectedOption.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: transparent !important;
  border-color: #1890ff !important;
  color: #000;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl {
  margin: 3px !important;
}

#selectedOption.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background: transparent !important;
  border-color: #1890ff !important;
  color: #000;
}

.ant-radio-button-wrapper:not(:first-child)::before,
#selectedOption.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
#selectedOption.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background-color: transparent !important;
}

.ant-form .ant-form-item .ant-form-item-label,
.ant-form .ant-form-item .ant-form-item-control {
  flex: initial !important;
}

@media (max-width: 767px) {
  .ant-modal {
    max-width: 100% !important;
  }
}

.ant-spin-nested-loading {
  width: 100% !important;
}

.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
  position: absolute;
  top: 14px;
  right: 12px;
}

.label-left .ant-form-item-row .ant-form-item-label {
  text-align: left;
}

.ant-spin-nested-loading>div>.ant-spin .ant-spin-dot {
  margin: -30px;
}

.ant-select-dropdown {
  z-index: 999999999;
}

.ant-upload.ant-upload-select-picture-card {
  width: 100%;
}

.ant-image-preview-img-wrapper {
  position: initial;
  display: flex;
  min-height: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.ant-image-mask-info {
  height: 20px;
}

.ant-image {
  width: 100%;
  height: 100%;
}

/* modal */
.ant-modal-body {
  background-color: var(--bg-white) !important;
}