.product-detail__thumb-slider {
  .child {
    display: flex;
    margin-right: calc(-50% + 49px);
  }
  .swiper-slide {
    opacity: 0.4;
    width: 91px;
    height: 91px;
  }
  
  .swiper-container {
    width: 100%;
    // position: static !important;
  }
  .swiper-button-prev,
  .swiper-button-next {
    font-size: 1.33rem;
    color: #000;
    font-weight: 700;
    z-index: 10;
    width: 32px;
    height: 32px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #e0e0e0;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
    top: unset;
    bottom: 37px;
    &:after {
      @extend .icon;
      content: '\E905' !important;
    }
  }
  .swiper-button-prev {
    right: 0;
    &::after {
      transform: rotate(-180deg);
    }
  }
  .swiper-button-next {
    left: 0;
  }
}
.white-btn {
  .swiper-button-prev,
  .swiper-button-next {
    margin: -13px 0 0 -13px;
    font-size: 2rem;
    color: #000;
    font-weight: 700;
    z-index: 10;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 50%;
    &:after {
      @extend .icon;
      content: '\E905' !important;
    }
  }
  .swiper-button-prev {
    &::after {
      transform: rotate(-180deg);
    }
  }
}
.product-slider {
  .swiper-container {
    margin-right: -2px;
  }
  .swiper-slide {
    &:not(:first-of-type) {
      border-right: 1px solid #eeeeee;
    }
    transition: box-shadow 0.1s cubic-bezier(0.4, 0, 1, 1),
      transform 0.1s cubic-bezier(0.4, 0, 1, 1);
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
        border-right: none;
        transform: translateY(-2px);
        z-index: 11;
      }
      &:hover + div {
        border-right: none;
      }
    }
    @media screen and (min-width: 62em) {
      max-width: 285px;
    }
    @media screen and (max-width: 61.99em) {
      max-width: 220px;
    }
  }
  .swiper-button-next {
    left: 30px;
  }
  .swiper-button-prev {
    right: 16px;
  }
  .product__card {
    border-radius: 0;
  }
  .product__price-box {
    bottom: 12px;
    justify-content: space-between;
  }
}
.product-detail__slider {
  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-pagination {
    position: fixed;
    bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    .swiper-pagination-bullet-active {
      background-color: var(--color-success);
    }
    @media screen and (min-width: 62em) {
      display: none;
    }
  }
}
.landing-app-slider {
  .swiper-container {
    border-radius: 24px;
    z-index: 100;
    @media screen and (min-width: 75em) {
      // height: 110%;
    }
    @media screen and (max-width: 74.99em) {
      // height: 438px;
    }
  }
  .swiper-wrapper {
    @media screen and (min-width: 75em) {
      height: 462px;
    }
    @media screen and (max-width: 74.99em) {
      height: 385px;
    }
  }
  .swiper-pagination {
    width: 100%;
    position: fixed;
    @media screen and (min-width: 75em) {
      bottom: 104px;
    }
    @media screen and (max-width: 74.99em) {
      bottom: -34px;
    }
    .swiper-pagination-bullet {
      outline: none;
      &:not(:first-of-type) {
        margin-right: 8px;
      }
    }
    .swiper-pagination-bullet-active {
      background-color: #008eff;
    }
  }
  .swiper-slide {
    background-color: #fff;
    border-radius: 19px;
  }
}
.market-hero-slider {
  .swiper-container {
    height: 100%;
  }
  .swiper-slide {
    transition: all 0.3s ease-in-out;
    position: relative;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    cursor: pointer;
    border-radius: 16px;
  }
  .swiper-pagination {
    text-align: center;
    bottom: 16px !important;
  }
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
  .swiper-pagination-bullet {
    background-color: #fff;
    opacity: 0.8;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.3);
    @media screen and (min-width: 62em) {
      width: 10px;
      height: 10px;
    }
    @media screen and (max-width: 61.99em) {
      width: 8px;
      height: 8px;
    }
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        width: 11px;
        height: 11px;
        margin: 0 5.5px !important;
      }
    }
  }
  .swiper-pagination-bullet-active {
    background-color: #00bfd6;
    opacity: 1;
  }
  .swiper-pagination-bullets .swiper-pagination-bullet {
    @media screen and (min-width: 62em) {
      margin: 0 6px;
    }
    @media screen and (max-width: 61.99em) {
      margin: 0 4px;
    }
    &:focus {
      outline: none;
    }
  }
}
.market-special-swiper {
  padding: 24px 10px;
  @media screen and (min-width: 62em) {
    flex: calc(100% - 140px);
    max-width: calc(100% - 140px);
  }
  @media screen and (max-width: 61.99em) {
    width: 100%;
  }
  .swiper-container {
    width: 100%;
  }
  .swiper-slide {
    max-height: 344px;
    @media screen and (min-width: 62em) {
      max-width: 244px;
    }
    @media screen and (max-width: 61.99em) {
      max-width: 240px;
    }
    &:last-child {
      .special-swiper__box {
        padding-left: 0;
      }
    }
    &.hidden-md-up {
      .special-swiper-offers__title {
        height: 100%;
      }
    }
  }
  .swiper-button-next.swiper-button-disabled,
  .swiper-button-prev.swiper-button-disabled {
    display: none;
  }
}
.landing-pricing-slider {
  width: 100%;
  .swiper-container {
    width: 100%;
  }
  .swiper-wrapper {
    -webkit-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
  }
  .swiper-slide {
    width: auto !important;
  }
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}
