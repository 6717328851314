.list {
  list-style: none;
}
.itemLink {
  padding: 16px 24px 16px 16px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.16rem;
  line-height: 24px;
  color: #141414;
}

.badge {
  width: 24px;
  height: 24px;
  border-radius: 16px;
  background-color: var(--color-success);
  color: #fff;
  font-size: 1rem;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 16px;
  left: 16px;
}
