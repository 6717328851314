.container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
.cart {
  position: relative;
  width: 100%;
}
.addStore {
  font-size: 1.08rem;
  display: flex;
  align-items: center;
  position: relative;
  padding: 16px 24px;
  font-weight: 700;
  color: #424242;
  composes: icon from 'assets/style/global.scss';
  &:before {
    content: '\e9ce';
    font-size: 2rem;
    margin-left: 24px;
    color: var(--color-grey-1);
  }
}
.action {
  padding: 16px 16px 24px;
  width: 100%;
}
.loginBtn {
  background: #ffffff;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  font-size: 1.16rem;
  font-weight: 900;
  line-height: 16px;
  margin-bottom: 16px;
  composes: icon from 'assets/style/global.scss';
  &:before {
    content: '\ea45';
    font-size: 2rem;
    margin-left: 8px;
  }
}
.listContainer {
  flex: 0 0 100%;
}
.list {
  padding: 8px 0;
  list-style: none;
  border-top: 1px solid #eeeeee;
}
.link {
  font-size: 1.08rem;
  display: flex;
  align-items: center;
  position: relative;
  padding: 16px 24px;
  font-weight: 700;
  color: #424242;
  composes: icon from 'assets/style/global.scss';
  &:before {
    font-size: 2rem !important;
    margin-left: 24px;
    color: var(--color-grey-1);
  }
}
.logoutLink {
  font-size: 1.16rem;
  display: flex;
  align-items: center;
  line-height: 16px;
  padding: 16px;
  padding-right: 24px;
  position: relative;
  width: 100%;
  composes: icon from 'assets/style/global.scss';
  &:before {
    content: '\ea46';
    font-size: 2rem;
    margin-left: 16px;
  }
}
.store {
  position: relative;
  width: 100%;
  margin-bottom: 16px;
  padding: 16px 0;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}
