.ui {
  &__form-container {
    font-size: 0.857rem;
    line-height: 21px;
    color: #62666d;
    width: 100%;
    padding-bottom: 22px;
    &.error {
      .login__input {
        border-color: car(--color-error) !important;
      }
    }
  }
  &__form-field-row {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    &:not(:last-of-type) {
      margin-bottom: 24px;
    }
  }
  &__form-field {
    position: relative;
    width: 100%;
  }
  &__form-column {
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    @media screen and (min-width: 62em) {
      &:not(:last-child) {
        padding-left: 16px;
      }
    }
    @media screen and (max-width: 61.99em) {
      flex: 0 0 100%;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}
.ui-input {
  padding: 12px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  font-size: 1.166rem;
  line-height: 16px;
  width: 100%;
  height: 40px;
  line-height: normal;
  -webkit-appearance: none;

  &::placeholder {
    color: #616161;
    opacity: 0.8;
  }
  &:focus {
    border: 2px solid var(--color-primary);
    &::placeholder {
      color: transparent;
    }
  }
  &__error {
    border: 2px solid var(--color-error) !important;
    &--close {
      &::after {
        content: '\ea25';
        @extend .icon;
        font-size: 16px;
        position: absolute;
        left: 12px;
        top: 16px;
      }
    }
  }
  &__verified {
    border: 2px solid var(--color-success) !important;
    &--tick {
      &::after {
        content: '\e948';
        @extend .icon;
        font-size: 16px;
        position: absolute;
        left: 12px;
        color: #0cce6b;
        top: 12px;
      }
    }
  }
  &__text {
    margin-top: 4px;
    font-size: 1rem;
    line-height: 16px;
    margin-right: 16px;
    font-weight: normal;
    text-align: right;
    position: absolute;
    &--error {
      color: var(--color-error);
      @extend .ui-input__text;
    }
    &--verified {
      color: var(--color-success);
      @extend .ui-input__text;
    }
  }
  &__calender {
    &:before {
      content: '\e943';
      @extend .icon;
      font-size: 2rem;
      position: absolute;
      right: 12px;
      top: 12px;
    }
  }
  &__clock {
    &:before {
      content: '\e956';
      @extend .icon;
      font-size: 2rem;
      position: absolute;
      right: 12px;
      top: 12px;
    }
  }
}
.ui-input-gray {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 12px 16px;
  border: none;
  width: 100%;
  &::placeholder {
    color: var(--color-grey-1);
    opacity: 0.8;
  }
}
.ui-input-full {
  @extend .ui-input;
  height: 48px;
  &.disabled {
    background-color: #f5f5f5;
  }
  &:disabled {
    background-color: #f5f5f5;
  }
}
.ui-label,
.ant-form-item-label {
  margin-bottom: 8px;
  font-size: 1.2rem;
  line-height: 24px;
  color: #141414;
  display: block;
  margin-right: 16px;
  text-align: right;
}
.ui-input-percent {
  &::after {
    content: '\e910';
    @extend .icon;
    color: #141414;
    font-size: 2rem !important;
    position: absolute;
    left: 16px;
    top: 12px;
  }
}
.ui-input-toman {
  &::after {
    content: '\ea02';
    @extend .icon;
    color: #141414;
    font-size: 2rem !important;
    position: absolute;
    left: 16px;
    top: 12px;
  }
}
.ui-textarea {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 12px 16px;
  resize: none;
  width: 100%;
  font-size: 1.16rem;
  line-height: 24px;
  -webkit-appearance: none;
  &::placeholder {
    color: #9e9e9e;
    font-size: 1.16rem;
  }
  &:focus {
    border: 2px solid var(--color-primary);
    &::placeholder {
      color: transparent;
    }
  }
  & ~ .ui-input__text--error {
    bottom: -20px;
  }
}
.ui-input-btn {
  border: 1px solid #e0e0e0;
  padding: 12px;
  border-radius: 8px 0 0 8px;
  background-color: #fff;
  height: 48px;
  display: flex;
  align-items: center;
  &__container {
    display: inline-flex;
    width: 100%;
    position: relative;
    input {
      border-radius: 0 8px 8px 0;
      border-left: none;
      &.ui-input-btn__error {
        border: 2px solid var(--color-error);
        border-left: none;
        & ~ .ui-input-btn,
        button {
          border: 2px solid var(--color-error) !important;
          position: relative;
          &::before {
            content: '';
            display: none;
          }
        }
      }
      &:focus {
        border-left: none;
        & ~ .ui-input-btn,
        button {
          border: 2px solid var(--color-primary);
          border-right: none;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            height: 44px;
            width: 1px;
            background-color: #e0e0e0;
            right: 0;
          }
        }
      }
    }
    .ui-input__text--error {
      bottom: -20px;
    }
    button {
      border: 1px solid #e0e0e0;
      padding: 12px;
      border-radius: 8px 0 0 8px;
      background-color: #fff;
      height: 48px;
      cursor: pointer;
    }
  }
}
.ui-label-more-info {
  font-size: 1rem;
  line-height: 24px;
  color: var(--color-grey-1);
  margin-right: 4px;
}
.ui-English-input {
  text-align: left;
  direction: ltr;
  &::placeholder {
    text-align: left;
    direction: ltr;
  }
}
.checkbox {
  width: 24px;
  height: 24px;
  display: inline-block;
  position: relative;
  input {
    visibility: hidden;
    position: absolute;
    &:checked ~ .checkmark {
      background: var(--color-light-blue);
      border: 1px solid var(--color-light-blue);
      &:before {
        content: '\e947';
        @extend .icon;
        position: absolute;
        left: 50%;
        top: 50%;
        color: #fff;
        font-size: 1.33em;
        transform: translate(-50%, -50%);
      }
    }
  }
  .checkmark {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 4px;
    background: #fff;
    border: 1px solid #d8d8d8;
  }
}
.radio-label {
  display: flex;
  cursor: pointer;
  position: relative;
  font-size: 1.16rem;
  line-height: 24px;
  span {
    display: flex;
    align-items: center;
    border-radius: 99em;
    transition: 0.25s ease;
    &:before {
      display: flex;
      flex-shrink: 0;
      content: '';
      background-color: #fff;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      transition: 0.25s ease;
      border: 1px solid #e0e0e0;
    }
  }
}
.radio {
  position: absolute;
  visibility: hidden;
  &:checked + span {
    &:before {
      box-shadow: inset 0 0 0 5px #fff;
      background-color: var(--color-light-blue);
      border-color: var(--color-light-blue) !important;
    }
  }
  &:disabled + span {
    cursor: default;
  }
}
.p-radio {
  &:checked + span:before {
    box-shadow: inset 0 0 0 4px #fff !important;
  }
  & + span:before {
    border-width: 2px !important;
  }
}
.primary-radio {
  position: absolute;
  visibility: hidden;
  &:checked + .primary-switch-btn {
    &:before {
      box-shadow: inset 0 0 0 5px #fff;
      background-color: var(--color-success);
      border-color: var(--color-success) !important;
    }
  }
  // :checked + .profile-switch-btn:before {
  //   box-shadow: inset 0 0 0 5px #fff;
  //   border: 1px solid var(--color-success) !important;
  // }
  &:disabled + span {
    cursor: default;
  }
}
.p-switch-btn {
  &:before {
    width: 24px !important;
    height: 24px !important;
    border: 1px solid #bdbdbd !important;
  }
}
.p-gender-label {
  margin-bottom: 16px;
  color: #616161;
}
.radio:checked + .p-switch-btn:before {
  box-shadow: inset 0 0 0 5px #fff;
  border: 1px solid var(--color-light-blue) !important;
}
.primary-switch-btn {
  &:before {
    width: 24px !important;
    height: 24px !important;
    border: 1px solid #bdbdbd !important;
  }
}
// .profile-radio:checked + .profile-switch-btn:before {
//   box-shadow: inset 0 0 0 5px #fff;
//   border: 1px solid var(--color-success) !important;
// }
//remove rrow on type number

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* new */
.zp-toman-icon {
  &::after {
    content: '\ea02';
    @extend .icon;
    color: #141414;
    font-size: 2rem !important;
    position: absolute;
    left: 16px;
    top: 45px;
  }
}

.zp-percent-icon {
  &::after {
    content: '\e910';
    @extend .icon;
    color: #141414;
    font-size: 2rem !important;
    position: absolute;
    left: 16px;
    top: 45px;
  }
}

.ant-input-suffix {
  color: #141414 !important;
  font-size: 1.16rem !important;
  font-family: YekanBakh !important;
  font-weight: 900 !important;
}
