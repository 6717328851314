.container {
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
.smallBlue {
  width: 40px;
  height: 40px;
  border: 2px solid transparent;
  border-top: 2px solid var(--color-light-blue);
}
.bigGreen {
  @media screen and (min-width: 62em) {
    width: 64px;
    height: 64px;
    border: 6px solid #eeeeee;
    border-top: 6px solid var(--color-primary);
  }
  @media screen and (max-width: 61.99em) {
    width: 40px;
    height: 40px;
    border: 3px solid #eeeeee;
    border-top: 3px solid var(--color-primary);
  }
}
.smallGreen {
  width: 40px;
  height: 40px;
  border: 3px solid #eeeeee;
  border-top: 3px solid var(--color-primary);
}
.extraSmallGreen {
  width: 24px;
  height: 24px;
  border: 2px solid #eeeeee;
  border-top: 2px solid var(--color-primary);
}
.smallBlack {
  width: 40px;
  height: 40px;
  border: 3px solid #eeeeee;
  border-top: 3px solid #141414;
}

.background {
  // opacity: 0.3;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  // @media screen and (min-width: 62em) {
  // }
  // @media screen and (max-width: 61.99em) {
  //   min-height: -webkit-fill-available;
  // }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
